$mobile: 767px;
$tablet: 1020px;
$desktop: 1440px;
$laptop: 1660px;
$widescreen: 1920px;
$white: #fff;
$green-color: #2aa98b;
$red-color: #eb3d71;
$orange-color: #eeca47;
/*** 22 july ***/
$blue: #424ea0;
.text-blue {
  color: $blue !important;
}

.shakeHoverSmall {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  &:hover {
    // animation: Shake 0.5s linear infinite;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }
}
