body {
  margin: 0;
  padding: 0;
  font-family: "Averta";
}

* {
  box-sizing: border-box;
  line-height: normal;
  font-family: inherit;
  margin: unset;
}

a {
  text-decoration: none;
  display: block;
}

ul {
  margin: 0;
  margin-inline: unset !important;
  padding: 0;
  list-style: none;
}
