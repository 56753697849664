/*** 22 july ***/
.text-blue {
  color: #424ea0 !important;
}

.shakeHoverSmall {
  transform: scale(1);
  transition: 0.3s ease-in-out;
}
.shakeHoverSmall:hover {
  transform: scale(1.1);
  transition: 0.3s ease-in-out;
}/*# sourceMappingURL=trip-page.css.map */