body {
  background: #160539;
  overflow-x: hidden;
}

.about-card .head-2 {
  color: #000;
}

.about-card {
  perspective: 1000px;
  min-height: 450px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
@media (max-width: 767px) {
  .about-card {
    min-height: 250px;
  }
}

.footer-logo {
  max-width: 590px;
}
@media (max-width: 1536px) {
  .footer-logo {
    max-width: 470px;
  }
}
@media (max-width: 767px) {
  .footer-logo {
    max-width: 100%;
  }
}

.slick-slide {
  padding: 0 20px;
}
@media (max-width: 767px) {
  .slick-slide {
    padding: 0 10px;
  }
}

.menu {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}
.menu li {
  margin: 0 10px;
}
.menu li a {
  color: white;
  text-decoration: none;
  padding: 10px 15px;
}

.menu-icon {
  display: none;
  flex-direction: column;
  cursor: pointer;
}
.menu-icon .line {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 4px 0;
  transition: all 0.3s ease;
}
.menu-icon .line.open:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}
.menu-icon .line.open:nth-child(2) {
  opacity: 0;
}
.menu-icon .line.open:nth-child(3) {
  transform: rotate(315deg) translate(10px, -11px);
}

.hideDesktop {
  display: none;
}

/* Responsive styles */
@media (max-width: 1050px) {
  .hideDesktop {
    display: block;
  }
  .menu {
    display: none;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 62px;
    left: 0;
    background-color: #15053a;
  }
  .menu.open {
    display: flex;
  }
  .menu li {
    margin: 10px 0;
  }
  .menu-icon {
    display: flex;
    position: absolute;
    right: 34px;
    top: 21px;
  }
  .mainBg {
    background-image: none;
  }
}
.video-container {
  position: relative;
  width: 100%;
  height: 100vh;
}
@media (max-width: 990px) {
  .video-container {
    height: auto;
  }
}
.video-container.aboutVideo {
  height: 100%;
  min-height: 470px;
}
@media (max-width: 767px) {
  .video-container.aboutVideo {
    min-height: 300px;
  }
}
.video-container.aboutVideo .content {
  position: absolute;
}
.video-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

.video-background {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: 1;
  background-color: #000;
}

.content {
  position: relative;
  z-index: 2;
  color: white;
  text-align: center;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.registerBtn {
  transition: all 0.2s ease;
}
@media screen and (min-width: 1280px) and (max-width: 1536px) {
  .registerBtn {
    font-size: 30px !important;
    min-width: 400px;
    height: 96px;
  }
}
.registerBtn:hover {
  background: #ecc846;
}

.readMoreGreen {
  transition: all 0.2s ease;
}
.readMoreGreen:hover {
  background: #ecc846;
}

.readmoreOrange {
  transition: all 0.2s ease;
  background: #eb3d71;
  color: white;
}
.readmoreOrange:hover {
  background: #2aa98b;
  color: white;
}

@media (min-width: 1060px) and (max-width: 1599px) {
  .container-xs {
    max-width: 1400px;
  }
}
@media (min-width: 1600px) and (max-width: 1800px) {
  .container-xs {
    max-width: 1480px;
  }
}
.mainMenu li a {
  cursor: pointer;
  position: relative;
}
.mainMenu li a::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 0;
  right: 0;
  margin: 0 auto;
  height: 2px;
  background-color: #ecc846;
  transition: all 0.3s ease;
}
.mainMenu li a:hover h4, .mainMenu li a.active h4 {
  color: #ecc846 !important;
}
.mainMenu li a:hover::after, .mainMenu li a.active::after {
  width: 100%;
}
@media (min-width: 1050px) and (max-width: 1310px) {
  .mainMenu li h4 {
    font-size: 16px !important;
  }
}

.eventSlider .slick-slider {
  width: 100%;
}
.eventSlider .slick-slider .eventSlideItems {
  min-height: 884px;
}
@media screen and (max-width: 1050px) {
  .eventSlider .slick-slider .eventSlideItems {
    min-height: 684px;
  }
}
@media screen and (max-width: 990px) {
  .eventSlider .slick-slider .eventSlideItems {
    min-height: 624px;
  }
}
.eventSlider .slick-prev,
.eventSlider .slick-next {
  transform: translate(0, 0%);
  top: auto;
  bottom: -50px;
  width: 30px;
  height: 30px;
}
.eventSlider .slick-prev:before,
.eventSlider .slick-next:before {
  font-size: 30px;
}
.eventSlider .slick-prev {
  left: calc(50% - 40px);
}
.eventSlider .slick-next {
  right: calc(50% - 40px);
}

.about-card .head-2 {
  color: #000;
}

.bg-full {
  background-size: 100%;
}

.bg-about-sec {
  background-size: 10%;
  background-position: 20px bottom;
  animation: aboutAnim 5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.bg-schedule-sec {
  background-size: 100%;
  background-position: center center;
}

.bg-footer-sec {
  background-size: 100%;
  background-position: center bottom;
}

.border-footer {
  position: relative;
  z-index: 1;
}

.about-card.bg-amber-300 .flip-card-back {
  color: #000 !important;
}

.eventSlider .slick-slider .eventSlideItems.bg-amber-300 .text-white-a700,
.eventSlider .slick-slider .eventSlideItems.bg-amber-300 p {
  color: #000 !important;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.9) !important;
  z-index: 999 !important;
}

.ReactModal__Overlay .btnClose {
  position: absolute;
  right: 0;
  top: 0;
  padding: 5px;
  font-family: verdana;
  margin: 5px;
}

.about-card {
  perspective: 1000px;
  min-height: 450px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.goog-te-gadget > span {
  display: none;
}

.goog-te-gadget {
  font-size: 0;
  font-family: Averta;
}

select.goog-te-combo {
  font-size: 20px;
  margin: 0 !important;
  padding: 9px 10px;
  color: #7047a5;
  text-transform: uppercase;
  font-weight: 600;
  font-family: Averta;
  width: 230px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 767px) {
  select.goog-te-combo {
    font-size: 15px;
    width: 180px;
  }
}

/* select.goog-te-combo:hover{
  background-color: #9f85ff;
  color:#fff;
} */
.circle-bg {
  background-size: cover;
  animation: slidein 50s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.top-circle {
  background-size: 100%;
  animation: zoomin 30s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.f-bottom-circle {
  background-size: 100%;
  animation: zoomin 10s;
  background-position: left bottom;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.half-circle {
  background-size: 100%;
  animation: zoominhalf 30s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.top-arrow {
  background-size: 100%;
  animation: updown 5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.f-left-arrow {
  background-size: 100%;
  animation: fupdown 5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
@media (max-width: 767px) {
  .f-left-arrow {
    background: none;
  }
}

.f-arrow {
  animation: updowncenter 5s;
  background-position: center bottom;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  background-size: 50%;
  animation-direction: alternate;
}
@media (max-width: 1050px) {
  .f-arrow {
    background-size: 100% auto;
  }
}

.mainTopBg {
  background-size: 100%;
  background-position: top right;
  animation: mainzoom 8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.f-top-circle {
  background-size: 100%;
  background-position: top right;
  animation: mainzoom 8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.bg-bigger-dot {
  background-position: left center;
  animation: mainzoom 8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.bg-line-red-green {
  animation: mainzoom 10s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.bg-dot {
  animation: dotAnim 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
@media (max-width: 767px) {
  .bg-dot {
    background-image: none;
  }
}

.bg-dot-two {
  animation-delay: 0.4s;
}

.bg-dot-three {
  animation-delay: 0.8s;
}

.bg-dot-four {
  animation-delay: 1.2s;
}

.bg-dot-five {
  animation-delay: 1.6s;
}

.bg-dot-six {
  animation-delay: 2s;
}

.f-dot {
  animation: fdotAnim 2s;
  background-position: center bottom;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.f-dot-two {
  animation-delay: 0.4s;
}

.f-dot-three {
  animation-delay: 0.8s;
}

.f-dot-four {
  animation-delay: 1.2s;
}

.f-dot-five {
  animation-delay: 1.6s;
}

.f-dot-six {
  animation-delay: 2s;
}

.bg-arrow-green {
  animation: dotAnim 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.bg-arrow-yellow {
  animation: dotAnim 3s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
@keyframes slidein {
  from {
    background-position: top;
    background-size: 100%;
  }
  to {
    background-position: 400px 0px;
    background-size: 100%;
  }
}
@keyframes zoomin {
  from {
    background-size: 30%;
  }
  to {
    background-size: 75%;
  }
}
@keyframes zoominhalf {
  from {
    background-size: 100%;
  }
  to {
    background-size: 80%;
  }
}
@keyframes mainzoom {
  from {
    background-size: 110%;
  }
  to {
    background-size: 90%;
  }
}
@keyframes updown {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 100px;
  }
}
@keyframes fupdown {
  from {
    background-position: 0 bottom;
  }
  to {
    background-position: 0 70%;
  }
}
@keyframes updowncenter {
  from {
    background-position: center bottom;
  }
  to {
    background-position: center 70%;
  }
}
@keyframes aboutAnim {
  from {
    background-position: 20px bottom;
  }
  to {
    background-position: 20px 70%;
  }
}
@keyframes dotAnim {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 -100px;
  }
}
@keyframes fdotAnim {
  from {
    background-position: 0 bottom;
  }
  to {
    background-position: 0 90%;
  }
}
.font-tentative {
  font-size: 22px;
}

.whatsapp-icon {
  position: fixed;
  bottom: 50px;
  right: 20px;
  z-index: 999;
}

@media (max-width: 1700px) {
  .font-tentative {
    font-size: 20px;
  }
}
@media (max-width: 1535px) {
  .font-tentative {
    font-size: 20px;
  }
}
@media (max-width: 1400px) {
  .font-tentative {
    font-size: 1.65vw;
  }
}
@media (max-width: 1023px) {
  .font-tentative {
    font-size: 22px;
  }
  .vdo-home {
    margin-top: 60px;
  }
}
@media (max-width: 992px) {
  .vdo-home {
    margin-top: 60px;
  }
}
@media (max-width: 767px) {
  .about-card {
    min-height: 250px;
  }
  .font-tentative {
    font-size: 18px;
  }
}
.footer-logo {
  max-width: 590px;
}

@media (max-width: 1536px) {
  .footer-logo {
    max-width: 470px;
  }
}
@media (max-width: 767px) {
  .footer-logo {
    max-width: 100%;
  }
}
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.about-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flip-card-front {
  color: black;
}

.flip-card-back {
  background-color: #b92451;
  color: white;
  transform: rotateY(180deg);
  padding: 0 20px;
  font-size: 30px;
  text-align: justify;
}

@media (max-width: 1550px) {
  .flip-card-back {
    font-size: 24px;
  }
}
@media (max-width: 1050px) {
  .flip-card-back {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .flip-card-back {
    font-size: 16px;
    box-sizing: border-box;
  }
}
.slick-slide {
  padding: 0 20px;
}
.slick-slide div {
  vertical-align: middle;
}

@media (max-width: 767px) {
  .slick-slide {
    padding: 0 20px;
  }
}
.menu {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.menu li {
  margin: 0 10px;
}

.menu li a,
.menu li span {
  color: white;
  text-decoration: none;
  padding: 10px 15px;
  display: inline-block;
}
@media (max-width: 1050px) {
  .menu li a,
  .menu li span {
    width: 100%;
  }
}

.menu-icon {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.menu-icon .line {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 4px 0;
  transition: all 0.3s ease;
}

.menu-icon .line.open:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.menu-icon .line.open:nth-child(2) {
  opacity: 0;
}

.menu-icon .line.open:nth-child(3) {
  transform: rotate(315deg) translate(10px, -11px);
}

.hideDesktop {
  display: none;
}

/* Responsive styles */
@media (max-width: 1050px) {
  .hideDesktop {
    display: block;
  }
  .menu {
    display: none;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 111;
    background-color: #15053a;
  }
  .menu.open {
    display: flex;
  }
  .menu li {
    margin: 10px 0;
  }
  .menu-icon {
    display: flex;
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .mainBg {
    background-image: none;
  }
}
.video-container {
  position: relative;
  width: 100%;
}

.video-container.aboutVideo {
  height: 100%;
  min-height: 470px;
}

@media (max-width: 767px) {
  .video-container.aboutVideo {
    min-height: 300px;
  }
}
.video-container.aboutVideo .content {
  position: absolute;
}

.video-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 1px);
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

.video-background {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.content {
  position: relative;
  z-index: 2;
  color: white;
  text-align: center;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.registerBtn {
  transition: all 0.2s ease;
}

@media screen and (min-width: 992px) and (max-width: 1720px) {
  .registerBtn {
    font-size: 24px !important;
    min-width: 300px !important;
    height: 70px !important;
  }
  .\!text-\[84\.24px\] {
    font-size: 60px !important;
  }
  .in_progress {
    font-size: 4.3vw;
  }
  .pt-set {
    padding-top: 15px;
  }
  .mt-custom {
    margin-top: 0px;
  }
}
.registerBtn:hover {
  background: #ecc846;
}

.readMoreGreen {
  transition: all 0.2s ease;
}

.readMoreGreen:hover {
  background: #ecc846;
}

.readmoreOrange {
  transition: all 0.2s ease;
}

.readmoreOrange:hover {
  background: #2aa98b;
  color: white;
}

@media (min-width: 1060px) and (max-width: 1536px) {
  .container-xs {
    max-width: 1400px;
  }
}
@media (min-width: 1539px) and (max-width: 1700px) {
  .container-xs {
    max-width: 1480px;
  }
  .progress_heading {
    font-size: 3.4vw;
  }
}
.mainMenu li a,
.mainMenu li span {
  cursor: pointer;
  position: relative;
}

.mainMenu li a::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 0;
  right: 0;
  margin: 0 auto;
  height: 2px;
  background-color: #ecc846;
  transition: all 0.3s ease;
}

.mainMenu li a:hover h4,
.mainMenu li span:hover h4,
.mainMenu li a.active h4,
.mainMenu li span.active h4 {
  color: #ecc846 !important;
}

.mainMenu li a:hover::after,
.mainMenu li a.active::after {
  width: 100%;
}

.eventSlider .slick-slider {
  width: 100%;
}

.eventSlider .slick-slider .eventSlideItems {
  min-height: 884px;
}

@media screen and (max-width: 1440px) {
  .eventSlider .slick-slider .eventSlideItems {
    min-height: 779px;
  }
}
@media screen and (max-width: 1050px) {
  .eventSlider .slick-slider .eventSlideItems {
    min-height: 684px;
  }
}
@media screen and (max-width: 990px) {
  .eventSlider .slick-slider .eventSlideItems {
    min-height: 684px;
  }
  .hostedTitle {
    margin-bottom: 10px;
  }
  .footer-logo {
    max-width: 100%;
  }
  .registerBtn {
    font-size: 20px !important;
    height: 60px !important;
    min-width: 225px !important;
    margin-top: 20px;
  }
  .\!text-\[84\.24px\] {
    font-size: 4vw !important;
  }
  .in_progress {
    font-size: 4.2vw;
  }
}
.eventSlider .slick-prev,
.eventSlider .slick-next {
  transform: translate(0, 0%);
  top: auto;
  bottom: -20px;
  width: 30px;
  height: 30px;
}

.eventSlider .slick-prev:before,
.eventSlider .slick-next:before {
  font-size: 30px;
}

.eventSlider .slick-prev {
  left: calc(50% - 40px);
}

.eventSlider .slick-next {
  right: calc(50% - 40px);
}

.aboutSlider .slick-prev,
.aboutSlider .slick-next {
  transform: translate(0, 0%);
  top: auto;
  bottom: -20px;
  width: 30px;
  height: 30px;
}

.aboutSlider .slick-prev:before,
.aboutSlider .slick-next:before {
  font-size: 30px;
}

.aboutSlider .slick-prev {
  left: calc(50% - 40px);
}

.aboutSlider .slick-next {
  right: calc(50% - 40px);
}


@media screen and (max-width: 574px) {
  .sm-w-100 {
    width: 100% !important;
  }
  .footerMain {
    padding: 0 15px 40px;
  }
  .in_progress {
    font-size: 9.2vw;
  }
  .\!text-\[84\.24px\] {
    font-size: 6vw !important;
  }
  .eventSlider .slick-slider .eventSlideItems {
    min-height: 615px;
  }
}
.bulter {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 16px;
  margin-bottom: auto;
  margin-top: 13px;
}
@media (max-width: 1050px) {
  .bulter {
    width: 14px;
    height: 14px;
    margin-top: 8px;
  }
}
@media (max-width: 767px) {
  .bulter {
    width: 10px;
    height: 10px;
    margin-top: 5px;
  }
}

.essenceSec img {
  max-height: 230px;
}
@media (max-width: 767px) {
  .essenceSec img {
    max-height: 100px;
  }
}

.essenceCol {
  position: relative;
}
.essenceCol img {
  transition: all 0.5s ease;
}

/* Rotate */
.hover06 img {
  transform: rotate(0) scale(1);
  transition: 0.3s ease-in-out;
}

.hover06:hover img {
  transform: rotate(15deg) scale(0.8);
  -webkit-transition: 0.3s ease-in-out;
}

.aboutBg {
  background-size: 100% auto;
  position: relative;
  z-index: 1;
  padding: 0 30px;
}
@media (max-width: 1700px) {
  .aboutBg {
    max-width: 1520px;
    margin: 0 auto;
  }
  .aboutBg .text-\[90px\] {
    font-size: 70px;
  }
}
@media (max-width: 1700px) and (max-width: 767px) {
  .aboutBg .text-\[90px\] {
    font-size: 28px;
  }
}
@media (max-width: 767px) {
  .aboutBg {
    padding: 0;
  }
}

.halfCricleLineTwo {
  max-width: 600px;
  position: absolute;
  right: 0;
  z-index: 0;
  top: 50px;
}
@media (max-width: 1050px) {
  .halfCricleLineTwo {
    display: none;
  }
}
.halfCricleLineTwo.left {
  left: 0;
}
@keyframes anim_1 {
  0% {
    stroke-width: 0;
    stroke-color: #ecc846;
  }
  25% {
    stroke-width: 7;
    stroke-color: #3239cb;
  }
  50% {
    stroke-width: 9;
    stroke-color: #f3836f;
  }
  100% {
    stroke-width: 0;
    stroke-color: #2aa98b;
  }
}
@keyframes colorChange {
  0% {
    stroke: #ecc846;
  }
  25% {
    stroke: #ed3b70;
  }
  50% {
    stroke: #3239cb;
  }
  75% {
    stroke: #f3836f;
  }
  100% {
    stroke: #2aa98b;
  }
}
@keyframes anim_2 {
  0% {
    transform: scale(1) translateX(0);
    /*       opacity:0; */
    /*       visibility:hidden; */
  }
  50% {
    /*       opacity:1; */
    /*       visibility:visible; */
  }
  100% {
    transform: scale(1.1) translateX(-5%);
  }
}
.halfCricleLineTwo svg circle {
  animation: 5s linear 0s infinite anim_1;
}
.halfCricleLineTwo svg {
  transform: scale(1);
  animation: 5s linear 0s infinite anim_2;
  position: absolute;
  right: 0;
  /*   opacity:0; */
  /*   visibility:hidden; */
}
.halfCricleLineTwo svg path {
  animation: colorChange 8s infinite;
}

.arrowDownLeftRed {
  position: absolute;
  top: 20%;
  left: 2%;
  z-index: -1;
}
@media (max-width: 1050px) {
  .arrowDownLeftRed {
    display: none;
  }
}
.arrowDownLeftRed.bottom {
  top: auto;
  bottom: 0%;
}
.arrowDownLeftRed.right {
  top: 30%;
  right: 2%;
  left: auto;
}
.arrowDownLeftRed.right.two {
  top: 50%;
}
.arrowDownLeftRed svg {
  max-width: 100%;
}
.arrowDownLeftRed .path {
  animation: moveDown 10s linear infinite;
}
.arrowDownLeftRed .path2 {
  animation: moveUp 10s linear infinite;
}
@keyframes moveDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
}
@keyframes moveUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-100%);
  }
}

.halfCricleLineOne {
  position: absolute;
  left: -12rem;
  z-index: -1;
}
@media (max-width: 1050px) {
  .halfCricleLineOne {
    display: none;
  }
}
.halfCricleLineOne .path {
  animation: dash 1.5s linear alternate infinite, colorChange 8s infinite;
}
@keyframes colorChange {
  0% {
    stroke: #eb3d71;
  }
  25% {
    stroke: #ff5733;
  }
  50% {
    stroke: #33ff57;
  }
  75% {
    stroke: #3357ff;
  }
  100% {
    stroke: #eb3d71;
  }
}
@keyframes dash {
  from {
    stroke-dashoffset: 360;
  }
  to {
    stroke-dashoffset: 40;
  }
}

.shakeHoverLarge {
  transform: scale(1);
  transition: 0.3s ease-in-out;
}
.shakeHoverLarge:hover {
  transform: scale(1.1);
  transition: 0.3s ease-in-out;
}
@media (max-width: 1600px) {
  .shakeHoverLarge {
    width: 90%;
    margin: 0px auto;
  }
}

.shakeHover {
  transform: scale(1);
  transition: 0.3s ease-in-out;
}
.shakeHover:hover {
  transform: scale(1.2);
  transition: 0.3s ease-in-out;
}

/*Using keyframes for shaking an image*/
@keyframes Shake {
  0% {
    transform: rotate(5deg);
  }
  25% {
    transform: rotate(-6deg);
  }
  50% {
    transform: rotate(5deg);
  }
  75% {
    transform: rotate(-6deg);
  }
  100% {
    transform: rotate(5deg);
  }
}
.footerBottomMenu {
  display: flex;
  justify-content: center;
  margin: 80px 0 40px;
}
@media (max-width: 767px) {
  .footerBottomMenu {
    margin-top: 0;
  }
}
.footerBottomMenu li {
  padding: 0 15px;
  position: relative;
}
.footerBottomMenu li a {
  font-weight: normal;
  color: white;
}
.footerBottomMenu li a:hover {
  color: #eecb48;
}
.footerBottomMenu li:first-child::after {
  content: "|";
  color: white;
  position: absolute;
  right: 0;
  top: 0;
}

.essence-gap {
  gap: 2vw;
}

.text-white {
  color: #fff !important;
}

.text-green {
  color: #29a98c !important;
}

.text-purple {
  color: #eb3d71 !important;
}

.EssenceHeadingsmall {
  font-size: 3.1vw;
  line-height: 1;
  font-weight: bold;
}
@media (max-width: 991px) {
  .EssenceHeadingsmall {
    font-size: 4.1vw;
  }
}
@media (max-width: 575px) {
  .EssenceHeadingsmall {
    font-size: 4.1vw;
  }
}

.EssenceHeadingBig {
  font-size: 5.2vw;
  line-height: 0.9;
  font-weight: bold;
}
@media (max-width: 991px) {
  .EssenceHeadingBig {
    font-size: 7.2vw;
  }
}
@media (max-width: 575px) {
  .EssenceHeadingBig {
    font-size: 8.1vw;
    line-height: 0.8;
  }
}
@media (max-width: 380px) {
  .EssenceHeadingBig {
    font-size: 9.1vw;
    line-height: 0.8;
  }
}

@media (max-width: 991px) {
  .faq-width {
    width: 100%;
  }
}

.shakeMain {
  justify-content: space-between;
  width: 100%;
  align-items: flex-end;
  gap: 5%;
  margin-top: 120px;
}
@media (max-width: 767px) {
  .shakeMain {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 767px) {
  .shakeMain img {
    width: 70%;
    margin: 20px auto;
  }
  .essence-main {
    margin-top: 40px;
  }
  .shakeHoverLarge {
    padding-bottom: 20px;
  }
  .footerlogoMain {
    margin-right: auto;
  }
}
.text-red {
  color: #eb3d71 !important;
}

.text-green {
  color: #2aa98b !important;
}

.text-orange {
  color: #eeca47 !important;
}

.text-gray {
  color: #b2b2b2 !important;
}

.mainHeadingInner {
  font-size: 80px;
  color: #2aa98b;
  font-weight: 600;
}
@media (max-width: 1700px) {
  .mainHeadingInner {
    font-size: 70px;
  }
}
@media (max-width: 1600px) {
  .mainHeadingInner {
    font-size: 60px;
  }
}
@media (max-width: 1500px) {
  .mainHeadingInner {
    font-size: 50px;
  }
}
@media (max-width: 1366px) {
  .mainHeadingInner {
    font-size: 40px;
  }
}
@media (max-width: 1200px) {
  .mainHeadingInner {
    font-size: 36px;
  }
}
@media (max-width: 767px) {
  .mainHeadingInner {
    font-size: 30px;
  }
}

.subheadingInner {
  font-size: 34px;
  padding-top: 20px;
  color: white;
  font-weight: 400;
  line-height: 50px;
}
@media (max-width: 1660px) {
  .subheadingInner {
    font-size: 24px;
    line-height: 35px;
  }
}
@media (max-width: 767px) {
  .subheadingInner {
    font-size: 18px;
    line-height: 27px;
    padding-top: 0px;
  }
  .subheadingInner p {
    line-height: 26px;
  }
}
.subheadingInner br {
  display: none;
}

.faqheading {
  font-size: 34px;
  color: white;
  font-weight: 700;
  line-height: 50px;
  padding: 16px;
}
@media (max-width: 767px) {
  .faqheading svg {
    max-width: 25px;
  }
}

.faqheadingsubtxt {
  font-size: 34px;
  color: white;
  font-weight: 700;
}

.ratio {
  position: relative;
  width: 100%;
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio::before {
  display: block;
  padding-top: 56.25%;
  content: "";
}

@media (max-width: 1050px) {
  .circalImg {
    justify-content: center;
    align-items: center;
  }
  .circalImg1 {
    justify-content: center;
    align-items: center;
    margin-top: 2.5rem;
  }
}
@media (max-width: 991px) {
  .pt-set {
    padding-top: 10px;
  }
  .mt-custom {
    margin-top: 0px;
  }
}
@media (max-width: 1600px) {
  .riemixMain {
    gap: 80px;
  }
  .shakeMain {
    margin-top: 80px;
  }
}
@media (max-width: 767px) {
  .shakeMain {
    margin-top: 40px;
  }
  .riemixMain {
    gap: 20px;
  }
}
.footerMain {
  margin-top: 4vw;
}

.customContainer {
  max-width: 1920px;
  padding-left: 30px;
  padding-right: 30px;
}

.scheduleCol {
  display: flex;
  border-radius: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin: 20px;
  margin-top: 0px;
  font-size: 36px;
  min-height: 210px;
  margin-bottom: 40px;
}
@media screen and (max-width: 1800px) {
  .scheduleCol {
    font-size: 34px;
  }
}
@media screen and (max-width: 1600px) {
  .scheduleCol {
    font-size: 24px;
  }
}
@media screen and (max-width: 1200px) {
  .scheduleCol {
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .scheduleCol {
    min-height: 110px;
    font-size: 16px;
    margin-left: 0;
    margin-top: 0;
    border-radius: 10px;
  }
}
@media screen and (max-width: 413px) {
  .scheduleCol {
    font-size: 16px;
    padding: 10px;
  }
}
.scheduleCol .eventTime {
  font-weight: normal;
  margin-bottom: 10px;
}
.scheduleCol .eventTitle {
  font-weight: 700;
  text-align: center;
}
.scheduleCol.red {
  background: #eb3d71;
  color: #fff;
}
.scheduleCol.yellow {
  background: #ecc846;
  color: #231f20;
}
.scheduleCol.green {
  background: #2aa98b;
  color: #fff;
}
.scheduleCol.blue {
  background: #424fa0;
  color: #fff;
}

.eventDate {
  font-size: 50px;
  color: #fff;
  font-weight: 600;
}
@media (max-width: 1700px) {
  .eventDate {
    font-size: 35px;
  }
}
@media (max-width: 1600px) {
  .eventDate {
    font-size: 30px;
  }
}
@media (max-width: 1366px) {
  .eventDate {
    font-size: 25px;
  }
}
@media (max-width: 767px) {
  .eventDate {
    font-size: 22px;
  }
}

.hostedTitle {
  font-size: 38px;
  color: #ecc746;
  font-weight: 700;
}
.hostedTitle .f-w-normal {
  font-weight: 400;
}
@media (max-width: 767px) {
  .hostedTitle {
    font-size: 24px;
  }
}

.aboutSubheading {
  color: #ecc846;
  font-size: 50px;
  font-weight: 800;
}
@media (max-width: 1440px) {
  .aboutSubheading {
    font-size: 36px;
  }
}
@media (max-width: 767px) {
  .aboutSubheading {
    font-size: 26px;
  }
}

.aboutSlider .slick-next,
.aboutSlider .slick-prev {
  bottom: -50px;
}

.privacyPage p {
  margin-bottom: 30px;
  font-size: 34px;
  padding-top: 20px;
  color: white;
  font-weight: 700;
  line-height: 54px;
}

.privacyPage ul li {
  margin-bottom: 20px;
  font-size: 34px;
  color: white;
  font-weight: 700;
  line-height: 54px;
}

.slick-dots li {
  width: auto;
  height: auto;
  margin: 0;
}

.slick-dots li button:before {
  border-radius: 100%;
  background-color: #fff;
  font-size: 0;
  opacity: 1;
  position: inherit;
  display: inline-block;
  margin: 0 2px;
  transition: all ease 0.4s;
  height: 0.5rem;
  width: 0.5rem;
}

.eventSlider .slick-dots li.slick-active button:before {
  background-color: #f00;
}

.eventYellowSlider .slick-dots li.slick-active button:before {
  background-color: #ecc846;
}

.eventGreenSlider .slick-dots li.slick-active button:before {
  background-color: #2aa98b;
}

.text-blue {
  color: #424ea0 !important;
}

.shakeHoverSmall {
  transform: scale(1);
  transition: 0.3s ease-in-out;
}
.shakeHoverSmall:hover {
  transform: scale(1.1);
  transition: 0.3s ease-in-out;
}

@media (max-width: 990px) {
  .eventSlider,
  .eventGreenSlider {
    margin: 0;
    padding-left: 10px;
  }
}
.eventSlider .slick-slide,
.eventGreenSlider .slick-slide {
  padding: 0 5px;
}

.redeventSlider .slick-slide,
.eventYellowSlider .slick-slide,
.eventGreenSlider .slick-slide {
  padding: 0;
}

.slick-dots {
  bottom: 5px;
}

iframe.skiptranslate {
  opacity: 0 !important;
}

.event-pic-box {
  align-items: flex-end;
}
@media (max-width: 767px) {
  .event-pic-box .subheadingInner {
    min-height: auto;
    margin-top: 25px;
  }
}

.alpha-view {
  list-style: lower-alpha;
  padding-left: 55px;
}
@media (max-width: 767px) {
  .alpha-view {
    padding-left: 30px;
  }
}

.cencelpolicyMain {
  margin-left: 10vw;
  margin-top: 5vw;
}
@media (max-width: 991px) {
  .cencelpolicyMain {
    margin-left: 0vw;
    margin-top: 3vw;
  }
}

.cencelpolicyHeading {
  font-size: 40px;
  color: #fff;
  font-weight: 700;
}
@media (max-width: 767px) {
  .cencelpolicyHeading {
    font-size: 24px;
  }
}

.cencelpolicycontent {
  padding-bottom: 80px;
}
@media (max-width: 767px) {
  .cencelpolicycontent {
    padding-bottom: 30px;
  }
}

.cencelpolicycontent p {
  font-size: 34px;
  padding: 20px 0px;
  color: white;
  font-weight: 400;
  line-height: 50px;
}
@media (max-width: 1600px) {
  .cencelpolicycontent p {
    font-size: 24px;
    line-height: 35px;
  }
}
@media (max-width: 767px) {
  .cencelpolicycontent p {
    font-size: 18px;
    line-height: 27px;
    padding: 10px 0px;
  }
}

.cencelpolicycontent p a {
  display: inline;
}

.cencelpolicycontent ul {
  padding: 20px 0px;
}
@media (max-width: 767px) {
  .cencelpolicycontent ul {
    padding: 10px 0px;
  }
}

.cencelpolicycontent ul li {
  font-size: 34px;
  color: white;
  font-weight: 600;
  padding: 5px 0px;
  display: flex;
}
@media (max-width: 1600px) {
  .cencelpolicycontent ul li {
    font-size: 24px;
  }
}
@media (max-width: 767px) {
  .cencelpolicycontent ul li {
    font-size: 18px;
  }
}

.bg-yello {
  background-color: #eeca47;
}

.bg-purple {
  background-color: #eb3d71;
}

.bg-green {
  background-color: #2aa98b;
}

@media (min-width: 1041px) and (max-width: 1150px) {
  .logo-main img {
    height: 30px;
  }
}
@media (max-width: 1050px) {
  .logo-main {
    position: absolute;
    width: 90px;
    left: 10px;
    top: 18px;
  }
  .logo-main img {
    height: auto;
  }
  .mobileHeader {
    justify-content: end;
    padding-right: 70px;
  }
}
.banner_home {
  width: 100%;
  position: relative;
}
.banner_home .hola-banner-person {
  position: absolute;
  right: -70px;
  bottom: -1px;
  left: auto;
  top: auto;
  width: 34vw;
  max-width: 670px;
  z-index: 1;
}
@media (max-width: 1920px) {
  .banner_home .hola-banner-person {
    right: -3vw;
  }
}
@media (max-width: 1600px) {
  .banner_home .hola-banner-person {
    right: -1.5vw;
  }
}
@media (max-width: 767px) {
  .banner_home .hola-banner-person {
    right: -2.5vw;
  }
}

.listing-blueter {
  list-style: none;
  padding-left: 30px;
}
@media (max-width: 767px) {
  .listing-blueter {
    padding-left: 15px;
  }
}
.listing-blueter li {
  margin-bottom: 25px;
  position: relative;
  padding: 0 0 0 30px;
}
.listing-blueter li::after {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: #2aa98b;
  border-radius: 50%;
  left: 0px;
  top: 10px;
}
@media (max-width: 767px) {
  .listing-blueter li::after {
    width: 10px;
    height: 10px;
  }
}
.listing-blueter.red li::after {
  background-color: #eb3d71;
}

@media (max-width: 1660px) {
  .faqheading {
    font-size: 24px;
  }
}
@media (max-width: 767px) {
  .faqheading {
    font-size: 18px;
  }
}

.fade-enter {
  opacity: 0;
  transition: opacity 0.5s;
}

.fade-enter-active {
  opacity: 1;
}

.fade-leave {
  opacity: 1;
  transition: opacity 0.5s;
}

.fade-leave-active {
  opacity: 0;
}

.flip-card-image {
  background-color: transparent;
  display: inline-block;
  perspective: 1000px;
}
.flip-card-image .flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}
.flip-card-image .flip-card:focus {
  outline: 0;
}
.flip-card-image:hover .flip-card-inner, .flip-card-image:focus .flip-card-inner {
  transform: rotateY(180deg);
}
.flip-card-image .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
.flip-card-image .flip-card-front {
  color: black;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  position: inherit;
}
.flip-card-image .flip-card-back {
  color: white;
  transform: rotateY(180deg);
  z-index: 1;
  display: flex;
  font-size: 20px;
  padding: 4rem;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1440px) {
  .flip-card-image .flip-card-back {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .flip-card-image .flip-card-back {
    font-size: 12px;
    padding: 1rem;
  }
}
.flip-card-image .flip-card-back p {
  line-height: 38px;
}
@media (max-width: 1440px) {
  .flip-card-image .flip-card-back p {
    line-height: 28px;
  }
}
@media (max-width: 767px) {
  .flip-card-image .flip-card-back p {
    line-height: 16px;
  }
}

.bookNowBtn {
  font-size: 46px;
  font-weight: 700;
  line-height: 50px;
  color: #150539;
  border-radius: 10px;
  background: #eb3d71;
  padding: 15px;
  min-width: 310px;
  display: inline-block;
  text-align: center;
  margin-top: 20px;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 767px) {
  .bookNowBtn {
    font-size: 26px;
    padding: 6px 25px;
    min-width: inherit;
  }
}
.bookNowBtn:hover {
  border-radius: 0;
}
.bookNowBtn.yellow {
  background: #ecc846;
  color: #150539;
}

.fz40 {
  font-size: 40px;
}
@media (max-width: 1460px) {
  .fz40 {
    font-size: 34px;
  }
}
@media (max-width: 767px) {
  .fz40 {
    font-size: 24px;
  }
}

.yellowHeading {
  font-size: 54px;
  font-weight: 700;
  margin-bottom: 40px;
}
@media (max-width: 1460px) {
  .yellowHeading {
    font-size: 44px;
  }
}
@media (max-width: 767px) {
  .yellowHeading {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.yellowBullet {
  list-style: disc;
  padding-left: 100px;
  margin: 0;
}
@media (max-width: 767px) {
  .yellowBullet {
    padding-left: 30px;
  }
}
.yellowBullet li {
  font-size: 34px;
  color: #fff;
  padding-left: 20px;
  margin-bottom: 20px;
  font-weight: normal;
}
@media (max-width: 1660px) {
  .yellowBullet li {
    font-size: 24px;
  }
}
@media (max-width: 767px) {
  .yellowBullet li {
    font-size: 18px;
    line-height: 22px;
    padding-left: 10px;
    margin-bottom: 14px;
  }
}
.yellowBullet li::marker {
  color: #ecc846;
  font-size: 40px;
  line-height: 0;
}
@media (max-width: 767px) {
  .yellowBullet li::marker {
    font-size: 30px;
  }
}
.yellowBullet li a {
  color: #ecc846;
  display: inline-block;
}

.soldoutBtn {
  color: #fff;
  font-size: 40px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 15px;
}
@media screen and (max-width: 1536px) {
  .soldoutBtn {
    font-size: 34px;
  }
}
@media screen and (max-width: 767px) {
  .soldoutBtn {
    font-size: 24px;
  }
}

/*# dinesh gupta  */
.myeoPopup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  padding: 20px 0px;
  outline: none;
  max-width: 1744px;
  width: calc(100% - 20px);
  background: #160539;
}

.myeopopupBox {
  padding: 0px;
  margin-top: 30px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 80px) !important;
  position: relative;
}

.PointBox {
  width: 100%;
}

.PointBox ul {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.PointBox li {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.text-purple {
  color: #9f85ff !important;
}

.whiteBullet {
  list-style: disc;
  padding-left: 40px;
  margin: 0;
}

.whiteBullet li {
  font-size: 34px;
  color: #fff;
  padding-left: 20px;
  margin-bottom: 20px;
  font-weight: normal;
}

.ReactModal__Body--open {
  overflow: hidden !important; /* Allow scrolling */
}

.ReactModal__Content.myeoPopup .btnClose {
  background: #ec3e72;
}

.AppDownloadButton {
  display: flex;
}

.AppDownloadButton a {
  background-color: #ecc846;
  padding: 1.5rem 3rem;
  font-size: 34px;
  color: #000;
  border-radius: 100px;
  text-align: center;
}

.myeoPopup .arrowDownLeftRed.left {
  left: 1%;
}

.myeoPopup .arrowDownLeftRed.right {
  top: 30%;
  right: 1%;
  left: auto;
}

.myeoPopup .yellowBullet {
  padding-left: 40px;
}

.myeoPopup .essenceCol img {
  transform: scale(0.6);
}
@media screen and (max-width: 1050px) {
  .myeoPopup .essenceCol img {
    transform: none;
    max-width: 50%;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1600px) {
  .whiteBullet li {
    font-size: 24px;
    line-height: 35px;
  }
}
@media screen and (max-width: 1440px) {
  .myeoPopup .arrowDownLeftRed svg {
    max-width: 77%;
  }
}
@media screen and (max-width: 1366px) {
  .myeoPopup .arrowDownLeftRed svg {
    max-width: 77%;
  }
}
@media screen and (max-width: 1280px) {
  .myeoPopup .arrowDownLeftRed.left {
    left: 0%;
  }
  .myeoPopup .arrowDownLeftRed.right {
    right: 0%;
  }
  .myeoPopup .arrowDownLeftRed svg {
    max-width: 77%;
  }
}
@media screen and (max-width: 767px) {
  .whiteBullet li {
    font-size: 18px;
    line-height: 22px;
    padding-left: 10px;
    margin-bottom: 14px;
  }
  .myeoPopup .yellowBullet {
    padding-left: 30px;
  }
  .myeoPopup {
    padding: 20px 20px;
  }
  .myeopopupBox {
    padding: 0px 0px;
  }
  .PointBox ul {
    gap: 1rem;
  }
  .whiteBullet {
    padding-left: 20px;
  }
  .AppDownloadButton a {
    padding: 1rem 1rem;
    font-size: 18px;
  }
}
@media (min-width: 1050px) and (max-width: 1289px) {
  .logo-main img {
    max-height: 38px !important;
  }
}
.dropdownMenu {
  background-color: #fff;
  margin-top: 20px;
}
.dropdownMenu a,
.dropdownMenu span {
  text-transform: uppercase;
}
@media (max-width: 1050px) {
  .dropdownMenu {
    width: 100%;
    margin-top: 10px;
    border-radius: 0;
    position: initial;
  }
  .dropdownMenu a,
  .dropdownMenu span {
    color: #15053a !important;
    display: block;
    width: 100%;
  }
}
.dropdownMenu a {
  font-size: 16px;
}
.dropdownMenu a::after {
  display: none;
}

@media (max-width: 767px) {
  .sustainabilityListing {
    flex-direction: column;
    margin-bottom: 10px;
    justify-content: flex-start;
    text-align: left;
    align-items: flex-start;
  }
}

/*# dinesh gupta The End  */
.mainMenu li {
  position: relative;
}

.socailBanners {
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  transition: all 0.2s ease;
  overflow: hidden;
}
.socailBanners h3 {
  font-size: 50px;
  font-weight: 700;
  color: #fff;
  margin-top: 10px;
}
@media (max-width: 767px) {
  .socailBanners h3 {
    font-size: 22px;
  }
}
.socailBanners .hoverElements {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  z-index: 1;
  padding: 30px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  transform: scale(0);
  transition: 0.5s ease;
  overflow-y: auto;
}
@media (max-width: 767px) {
  .socailBanners .hoverElements {
    padding: 10px;
  }
  .socailBanners .hoverElements .subheadingInner {
    font-size: 16px;
  }
}
.socailBanners:hover img, .socailBanners:active img {
  opacity: 0;
}
.socailBanners:hover .hoverElements, .socailBanners:active .hoverElements {
  transform: scale(1);
}

.learnMore {
  background-color: #eb3d71;
  font-size: 20px;
  font-weight: 700;
  border-radius: 10px;
  padding: 15px 30px;
  color: #fff;
  display: inline-block;
  margin-top: 20px;
  transition: 0.5s ease;
}
@media (max-width: 767px) {
  .learnMore {
    font-size: 16px;
    padding: 10px 25px;
  }
}
.learnMore:hover {
  background-color: #eeca47;
}

/* Zoom-in animation */
@keyframes zoomIn {
  from {
    transform: scale(0.7);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
.ReactModal__ContentCustom {
  animation: zoomIn 0.5s ease-out;
}

.socailsmodalDetails h3 {
  font-size: 50px;
  font-weight: 700;
  color: #fff;
  margin-top: 10px;
}
@media (max-width: 767px) {
  .socailsmodalDetails h3 {
    font-size: 22px;
  }
}

.speaker-card {
  position: relative;
}
.speaker-card .speaker-img {
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  display: inline-block;
  background: #fff;
}
.speaker-card .speaker-img .overlaydiv {
  position: absolute;
  background: #ecc846;
  text-align: center;
  bottom: 0px;
  transition: all 0.7s ease;
  height: 0;
}
.speaker-card .speaker-img .speaker-descp {
  padding: 15px;
  max-height: 100%;
  overflow-y: auto;
  padding-bottom: 10px;
}
.speaker-card .speaker-img .speaker-descp h4 {
  font-size: 26px;
  border-bottom: 1px solid #231f20;
  padding-bottom: 10px;
  text-transform: uppercase;
  margin-bottom: 20px;
}
@media (max-width: 1460px) {
  .speaker-card .speaker-img .speaker-descp h4 {
    font-size: 22px;
  }
}
@media (max-width: 1260px) {
  .speaker-card .speaker-img .speaker-descp h4 {
    font-size: 18px;
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .speaker-card .speaker-img .speaker-descp h4 {
    font-size: 20px;
  }
}
.speaker-card .speaker-img .speaker-descp p {
  font-size: 20px;
  line-height: 24px;
}
@media (max-width: 1460px) {
  .speaker-card .speaker-img .speaker-descp p {
    font-size: 18px;
    line-height: 22px;
  }
}
@media (max-width: 1260px) {
  .speaker-card .speaker-img .speaker-descp p {
    font-size: 14px;
    line-height: 18px;
  }
}
@media (max-width: 767px) {
  .speaker-card .speaker-img .speaker-descp p {
    font-size: 18px;
    line-height: 22px;
  }
}
.speaker-card .speaker-img img {
  transition: all 0.3s ease;
  -o-object-fit: cover;
     object-fit: cover;
}
.speaker-card .speaker-img:hover .overlaydiv {
  height: calc(100% + 0px);
}
.speaker-card.type2 .overlaydiv {
  background: #2aa98b;
  color: #fff;
}
.speaker-card.type2 .overlaydiv h4 {
  border-bottom: 1px solid #fff;
}
.speaker-card.type3 .overlaydiv {
  background: #eb3d71;
  color: #fff;
}
.speaker-card.type3 .overlaydiv h4 {
  border-bottom: 1px solid #fff;
}
.speaker-card.type4 .overlaydiv {
  background: #424fa0;
  color: #fff;
}
.speaker-card.type4 .overlaydiv h4 {
  border-bottom: 1px solid #fff;
}/*# sourceMappingURL=custom.css.map */