@font-face {
  font-family: "Averta";
  src: url("./fonts/Averta-Black.woff2") format("woff2"),
    url("./fonts/Averta-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Averta";
  src: url("./fonts/Averta-ExtraBold.woff2") format("woff2"),
    url("./fonts/Averta-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Averta";
  src: url("./fonts/Averta-Bold.woff2") format("woff2"),
    url("./fonts/Averta-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Averta";
  src: url("./fonts/Averta-Regular.woff2") format("woff2"),
    url("./fonts/Averta-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Averta";
  src: url("./fonts/Averta-Light.woff2") format("woff2"),
    url("./fonts/Averta-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Averta";
  src: url("./fonts/Averta-Semibold.woff2") format("woff2"),
    url("./fonts/Averta-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
